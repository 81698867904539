import React from 'react'
import './GoogleButton.css'
import Google from '../../Assets/Google.svg';

const GoogleButton = () => {
  return (
    <button className="gsi-material-button">
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <img src={Google} alt='Google Login' />
        </div>
        <span class="gsi-material-button-contents">Continue with Google</span>
        <span style={{ display: 'none' }}>Continue with Google</span>
      </div>
    </button>
  )
}

export default GoogleButton




