import React from 'react'
import './ChatLayout.css'
const ChatLayout = ({children, variant='main', className=''}) => {
  return (
    <div className={'chat-layout-'+variant+' '+className}>
        {children}
    </div>
  )
}

const Main = ({...props}) => <ChatLayout {...props} variant='main'/>
const Left = ({...props}) => <ChatLayout {...props} variant='left'/>
const Right = ({...props}) => <ChatLayout {...props} variant='right'/>
const Center = ({...props}) => <ChatLayout {...props} variant='center' className='scroll-container'/>
const Footer = ({...props}) => <ChatLayout {...props} variant='footer'/>
const Conent = ({...props}) => <ChatLayout {...props} variant='content'/>
const ChatBarHeader = ({...props}) => <ChatLayout {...props} variant='bar-header'/>

ChatLayout.FooterContent = Footer;
ChatLayout.MainContent = Main;
ChatLayout.ChatContentLeft = Left;
ChatLayout.UploadContent = Right;
ChatLayout.ConversationContent = Center;
ChatLayout.ChatContent = Conent;
ChatLayout.ChatBarHeader = ChatBarHeader


export default ChatLayout;