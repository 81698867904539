import './ChatBar.css'
import Button from '../Button/Button'
import SendButton from '../../Assets/SendButton.svg';
import UploadButton from '../../Assets/UploadButton.svg';



const ChatFooter = ({ fileInputRef, handleFileChange, onClick, message, handleInputChange, handleKeyPress, handleSendMessage }) => {

    return (
        <div className='text-input'>
            <div className="table">
                <div className="cell">
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Button.Upload onClick={onClick}><img src={UploadButton} alt='upload'></img></Button.Upload>
                </div>
                <div className="cell stretch">
                    <div className="textarea-container">
                        <textarea className="custom-textarea"
                            placeholder='Type your question here and press enter.'
                            value={message}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyPress}
                        ></textarea>
                    </div>
                </div>
                <div className="cell">

                    <Button.Search onClick={handleSendMessage}><img src={SendButton} alt="Send" /></Button.Search>
                </div>

            </div>
        </div>
    )
}

export default ChatFooter;