import React from "react";
import './ConversationContent.css';

const ConversationContentLayout = ({children})=>{
    return (
        <div className='content'>
            {children}
        </div>
    );
}

export default ConversationContentLayout;