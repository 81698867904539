import React from 'react';
import './CardContainer.css';
import DigitalTwin from '../../Assets/DigitalTwin.svg';
import Research from '../../Assets/Research.svg';
import MediAssist from '../../Assets/MediAssist.svg';
import Analyst from '../../Assets/Analyst.svg';
import Arrow from '../../Assets/ArrowWelcomePage.svg';
import { useNavigate } from 'react-router-dom';

const CardContainer = () => {
    const navigate = useNavigate();

    const handleMediAssistClick = () => {
        navigate('/chat');
    };

    const cards = [
        {
            title: "Digital Twin",
            description: "Tell Jishi about you and discover the best deals for yourselves from all over the internet",
            imgSrc: DigitalTwin,Arrow,
            onClick: () => {}
        },
        {
            title: "MediAssist",
            description: "Let Jishi be your Medical Assistant. Summarise reports, get diet plans and a lot more",
            imgSrc: MediAssist,
            onClick: handleMediAssistClick
        },
        {
            title: "Research",
            description: "Jishi can be your research buddy. Ask for any help you need around any topic",
            imgSrc: Research,
            onClick: () => {}
        },
        {
            title: "Analyst",
            description: "Let Jishi analyse your finances, datasets and a lot more. Get more from your numbers",
            imgSrc: Analyst,
            onClick: () => {}
        }
    ];

    return (
        <div className="wc-card-container">
            <div className="wc-flex-container">
                {cards.map((card, index) => (
                    <div key={index} className="welcome-card" onClick={card.onClick}>
                        <img src={card.imgSrc} alt={card.title} />
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardContainer;