import React from "react";
import InputText from "../../Component/InputFeild/InputText";
import "./InterestPageMainLayout.css";
import InputPhoneNumber from "../../Component/InputFeild/InputPhoneNumber";
import Button from "../../Component/Button/Button";

const InterestPageMainLayout = (
    { firstName,
        lastName,
        email,
        phoneNumber,
        phoneCode,
        companyName,
        industry,
        onChange,
        onSubmit,
        errors }
) => {
    return (
        <div className='interest-container'>
            <form >
                <div className="interest-inner-conent">
                    <div className="interest-inner-sub-conent">
                        
                        <InputText.Primary placeholder='First Name' value={firstName} onChange={(e) => onChange('firstName', e.target.value)} />
                        {
                            errors.firstName ? (<div className="error">{errors.firstName}</div>) :
                                (errors.lastName ? <div className="error"></div> : '')
                        }
                    </div>
                    <div className="interest-inner-sub-conent">
                        
                        <InputText.Primary placeholder='Last Name' value={lastName} onChange={(e) => onChange('lastName', e.target.value)} />
                        {
                            errors.lastName ? (<div className="error">{errors.lastName}</div>) :
                                (errors.firstName ? <div className="error"></div> : '')
                        }
                    </div>
                </div>
                <div className="interest-inner-sub-conent">

                    
                    <InputText.Primary placeholder='Email' value={email} onChange={(e) => onChange('email', e.target.value)} />
                    {errors.email && <div className="error">{errors.email}</div>}
                    
                    <InputPhoneNumber
                        value={phoneNumber}
                        onChange={(e) => onChange('phoneNumber', e.target.value)}
                        selectedOption={phoneCode}
                        handleChange={(e) => onChange('phoneCode', e.target.value)} />
                    {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                    <InputText.Primary placeholder='Company Name' value={companyName} onChange={(e) => onChange('companyName', e.target.value)} />
                    <InputText.Primary placeholder='Industry' value={industry} onChange={(e) => onChange('industry', e.target.value)} />
                    <div className="center-align">
                        <Button.Search className="submit-button" onClick={onSubmit}>Submit</Button.Search>
                    </div>

                </div>
            </form>

        </div>
    );
};

export default InterestPageMainLayout;