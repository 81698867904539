import React from 'react'
import ModalComponent from '../../Component/Modal/Modal';


const Modal = ({children, display, setDisplay}) => {
    const changeCurrDisplay = () => {
      setDisplay(prevState => !prevState);
    }

  return (
    <ModalComponent changeVisibility={changeCurrDisplay} currentDisp={display?'inherit':'hidden'}>{children}</ModalComponent>
  )
}

export default Modal;