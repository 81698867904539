import React from 'react'
import './ConversationContent.css'

const ConversationContent = ({ message, time, input, imgs }) => {


    return (
        <div className='chat-send-container'>

            <div key={input} className="chat-send-container-right">
                <span className='time-span'>{time}</span>
                <br />
                {imgs?(<img src={imgs} alt='Pagec'/>):('')}
                { message }
            </div>
        </div>
    )
}

export default ConversationContent;