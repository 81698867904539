import React, {useState} from "react";
import { UploadContext } from "../Contexts/UploadContext";

const UploadProvider =({children})=>{
    const [files, setFiles] = useState([]);

    const uploadFile = (data) => {
        setFiles([...files, {name:data}])
    };

    return (
        <UploadContext.Provider value={{ files, uploadFile }}>
          {children}
        </UploadContext.Provider>
    );
}

export default UploadProvider;