import React from 'react';
import useUpload from '../../Contexts/UploadContext';
import useChat from '../../Contexts/ChatContext';
import RightPanelNoUpload from '../../Component/RightPanelContent/RightPanelNoUpload';
import RightPanelUpload from '../../Component/RightPanelContent/RightPanelUpload';

const RightBarFileUpload = ()=>{
    const {files, uploadFile} = useUpload();
    const { sendMessage } = useChat();
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('File selected:', file.name);
            uploadFile(file.name);
            sendMessage(file.name);
            // You can process the file here
        }
    };

    if(!files.length) return (<><RightPanelNoUpload onClick={handleFileChange}/></>)
    return (<><RightPanelUpload files={files}/></>)
}

export default RightBarFileUpload;