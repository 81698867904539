import React, {useState} from "react";
import useSidebarToggle from "../../Contexts/SideBarToggleContext";
import LeftPanelConentComponent from "../../Component/LeftPanelConent/LeftPanelContent";
import LeftPanelContentSmallComponent from "../../Component/LeftPanelConent/LeftPanelContentSmall";
import Modal from "../../Features/Modal/Modal";
import Login from "../../Component/Login/Login";

const LoginModalComponent = ({showAuth, setAuth}) => {
    return (
        <>
            <Modal display={showAuth} setDisplay={setAuth}>
                <Login/>
            </Modal>
        </>
    )

}
const LeftPanelConent = () => {
    const { sideBarToggleStatus } = useSidebarToggle();
    const [showAuth, setAuth] = useState(false);
    const changeShowAuth = () => {
        setAuth(!showAuth)
    }

    return (
        <>
            <LoginModalComponent showAuth = {showAuth} setAuth={setAuth}/>
            {
                (sideBarToggleStatus) ? <><LeftPanelConentComponent onClick={changeShowAuth} /></> : <><LeftPanelContentSmallComponent onClick={changeShowAuth}/></>
            }
        </>
    )
}

export default LeftPanelConent;