import React, { useState } from "react";
import { ChatContext } from '../Contexts/ChatContext'
import getFormattedTime from "../Utils/getFormattedTime";

const ChatProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    const sendMessage = async (message) => {
        setIsLoading(true);
        setMessages([...messages, { ...message, time: getFormattedTime() }]);
        setIsLoading(false);
    };

    const responseMessage = () => {
        let index = 0;
        const createMsg = async () => {
            await setMessages((val) => {
                index = val.length;
                return [...val, { message: ' ', isLoading: true, isError: '', response: true, time: getFormattedTime() }]
            });
        }
        const setToken = async (token) => {
            
            await setMessages((val) => {
                val[index].message += token;
                return [...val];
            });
        }
        const setComplete = async () => {
            await setMessages((val) => {
                val[index].isLoading = false;
                return [...val];
            });
        }
        return {
            setToken,
            setComplete,
            createMsg
        }

    }

    return (
        <ChatContext.Provider value={{ messages, isLoading, sendMessage, responseMessage }}>
            {children}
        </ChatContext.Provider>
    );
}

export default ChatProvider;