import React from 'react';
import './Tooltip.css';
import Info from '../../Assets/Info.svg';

const Tooltip = ({ text }) => {
  return (
    <div className="tooltip-container">
      <img src={Info} alt="Info" className="info-icon" />
      <span className="tooltip-text">{text}</span>
    </div>
  );
};

export default Tooltip;