import React from 'react';
import './AppleButton.css';
import Apple from '../../../Assets/Apple.svg'; // Replace with the path to your Apple SVG file

const AppleButton = () => {
  return (
    <button className="apple-material-button">
      <div className="apple-material-button-state"></div>
      <div className="apple-material-button-content-wrapper">
        <div className="apple-material-button-icon">
          <img src={Apple} alt='Apple Login' />
        </div>
        <span className="apple-material-button-contents">Continue with Apple</span>
        <span style={{ display: 'none' }}>Continue with Apple</span>
      </div>
    </button>
  );
};

export default AppleButton;
