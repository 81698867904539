import React from 'react'
import'./DropDown.css'

const DropDown = ({ children, onChange,type='select',disabled = false, className = '',variant }) => {
    return (
        <select
        type={type}
        onChange={onChange} 
        className={`dropdown-${variant} ${className}`}
        disabled={disabled}
        >
            {children}
        </select>
    );
};

const Primary = (props) => <DropDown {...props} variant="primary"/>;

DropDown.Primary = Primary;

export default DropDown;