import React from 'react';
import './ChooseJishi.css'


const ChooseJishi = ({children}) => {
  return (
    <div className="Choose-container">
      <div className="CYJ-content">
        <div className="line left"></div>
        <h2 className="title">{children}</h2>
        <div className="line right"></div>
      </div>
    </div>
  );
};

export default ChooseJishi;