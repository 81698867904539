import React, {useState} from "react";
import { SideBarToggleContext } from "../Contexts/SideBarToggleContext";

const SideBarToggleProvider =({children})=>{
    const [sideBarToggleStatus, setStatus] = useState(true);

    const flipSideBar = () => {
        setStatus(!sideBarToggleStatus)
    };

    return (
        <SideBarToggleContext.Provider value={{ sideBarToggleStatus, flipSideBar }}>
          {children}
        </SideBarToggleContext.Provider>
    );
}

export default SideBarToggleProvider;