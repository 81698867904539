import React from 'react'
import '../Logo/Logo.css'
import JishiLogo from '../../Assets/JishiLogo.svg';
import Logo1 from '../../Assets/Logo1.svg';




const Logo = {
    Small: ({ className = '' }) => {
        return (<div className={className}>
                <img src={Logo1}/>
            
        </div>)
    },

    Full: ({ className = '' }) => {
        return (<div className={className}>
            <span className='jishi-text-log'>
                <Logo.Small />
                <img src={JishiLogo}/>

            </span>

        </div>)
    }
}

export default Logo