import React from 'react';
import Button from '../../Component/Button/Button';
import Tooltip from '../../Component/Tooltip/Tooltip';
import Compare from '../../Assets/Compare.svg';
import Summarise from '../../Assets/Summerise.svg';
import Outliers from '../../Assets/Outliers.svg';
import Suggest from '../../Assets/Suggestions.svg';
import Predict from '../../Assets/Predict.svg';
import ChatLayout from '../../Layout/ChatLayout/ChatLayout';


const ChatBarHeader = () => {
    
    return (
        <ChatLayout.ChatBarHeader>
            <Button.ChatPrimary>
            <img src={Summarise} alt='Summerise'/>
                Summarise
                <Tooltip text="Summarise"/>
            </Button.ChatPrimary>
            <Button.ChatPrimary>
                <img src={Compare} alt='Compare'/>
                Compare
                <Tooltip text="Compare 2 or more files to understand your recovery trend"/>
            </Button.ChatPrimary>
            <Button.ChatPrimary>
            <img src={Outliers} alt='Compare'/>
                Outliers
                <Tooltip text="Find out which vital of yours need urgent attention or is showing abnormal values"/>
            </Button.ChatPrimary>
            <Button.ChatPrimary>
            <img src={Suggest} alt='Suggest'/>
                Suggest
                <Tooltip text="Ask Jishi to suggest ways to keep your lifestyle healthy"/>
            </Button.ChatPrimary>
            <Button.ChatPrimary>
            <img src={Predict} alt='Predict'/>
                Predict
                <Tooltip text="Get Basic or Advanced predictions about your health conditions with a short quiz"/>
            </Button.ChatPrimary>
        </ChatLayout.ChatBarHeader>

    )
}

export default ChatBarHeader;