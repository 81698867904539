import React, {useState} from 'react';

const RightPanelUpload = ({ files = [] }) => {
    const [checked, setChecked] = useState(true);
    const handleChange = () => {
        setChecked(!checked);
    };
    return (

        <>
            <span><h1>My MediAssist Files</h1></span>
            <span>
                {
                    files.map((file, index) => {
                        
                        return (
                            <p>
                                <label> <input key={index} type='checkbox' name={index} checked={checked} onClick={handleChange} />
                                    {file.name} </label>
                            </p>
                        )
                    })
                }
            </span>
        </>
    )
}

export default RightPanelUpload;