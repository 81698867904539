import React,{useState} from 'react';
import './Modal.css';
import '../../Features/Modal/Modal';

const Modal = ({ children, changeVisibility, currentDisp}) => {
    return (
        <div className={'modal-login '+currentDisp}>
            <div className='overlay'>
                <div className='modal-content'>
                    <div className='modal-close-div'>
                        <button onClick={changeVisibility}>x</button>
                        {/* <span onClick={changeVisibility}>X</span> */}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;