import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatPage from './Routes/ChatPage/ChatPage';
import ChatProvider from './Providers/ChatProvider';
import SideBarToggleProvider from './Providers/SideBarToggleProvider';

import UploadProvider from './Providers/UploadProvider';
import WelcomePage from './Routes/WelcomePage/WelcomePage';

import InterestPage from './Routes/InterestPage/InterestPage';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/chat" element={
          <UploadProvider>
            <ChatProvider>
              <SideBarToggleProvider>
                <ChatPage />
              </SideBarToggleProvider>
            </ChatProvider>
          </UploadProvider>
        } />


        <Route path="/welcome" element={
          <SideBarToggleProvider>
            <WelcomePage />
          </SideBarToggleProvider>
        }>
        </Route>

        <Route path="/" element={
          <SideBarToggleProvider>
            <InterestPage />
          </SideBarToggleProvider>
        }>
        </Route>

      </Routes>
    </BrowserRouter>
  )
}

export default App;
