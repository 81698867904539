const randomMDString  = `
# Welcome to My Markdown Sample

## Introduction

This is a paragraph with **bold** and *italic* text. You can also combine them for ***bold and italic*** text.

### Lists

Here's an unordered list:

- Item 1
- Item 2
  - Subitem 2.1
  - Subitem 2.2
- Item 3

And here's an ordered list:

1. First item
2. Second item
3. Third item

## Links and Images

[Visit OpenAI](https://www.openai.com)

![Placeholder Image](https://via.placeholder.com/150)

## Blockquotes

> This is a blockquote.
> It can span multiple lines.

## Tables

| Column 1 | Column 2 | Column 3 |
|----------|----------|----------|
| Row 1, Col 1 | Row 1, Col 2 | Row 1, Col 3 |
| Row 2, Col 1 | Row 2, Col 2 | Row 2, Col 3 |

---

That's all for now!

`;

export default randomMDString;