import React from 'react';
import './SearchBar.css';
import SearchBar1 from '../../Assets/SearchBar1.svg';


const SearchBar = ({onClick=()=>true}) => {
  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search Chat"
      />
      <button onClick={onClick}>
      <img src={SearchBar1}/>
      </button>
    
    </div>
  );
};

export default SearchBar;

