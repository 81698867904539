import React, { useRef, useEffect } from "react";
import useChat from "../../Contexts/ChatContext";
import ChatLayout from "../../Layout/ChatLayout/ChatLayout";
import ConversationContent from './ConversationContent';

const ConversationContentMain = () => {
    const { messages } = useChat()
    const scrollRef = useRef(null);
    useEffect(() => {
        console.log('scrollRef.current',scrollRef.current)
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                }
            }, 0);
        
    }, [messages]);

    return (
        <ChatLayout.ConversationContent ref={scrollRef}>
            <ConversationContent messages={messages} />
        </ChatLayout.ConversationContent>
    )
}

export default ConversationContentMain;