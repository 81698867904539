// src/components/InputField.js
import React from 'react';
import '../InputFeild/InputFeild.css'; // Create CSS for styling

const InputField = ({ type, placeholder }) => {
  return (
    <input 
      type={type} 
      placeholder={placeholder} 
      className="input-field"
    />
  );
};

export default InputField;
