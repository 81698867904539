import React from 'react'
import Container from '../../Layout/Container/Container'
import SideBarToggle from '../../Features/SideBar/SideBarToggle'
import MainSection from '../../Layout/MainSection/MainSection'
import InterestPageLeftPanelConent from '../../Features/LeftPanelContent/InterestPageLeftPanelContent';
import InterestPageLayout from '../../Layout/WelcomePageLayout/InterestPageLayout'
const InterestPage = () => {
  return (
    <>
      <MainSection>
        <Container.Main className={'sidebar-open'}>
          <Container.Left>
            <InterestPageLeftPanelConent />
          </Container.Left>
          <Container.Right>
            <Container.Sub>
              <SideBarToggle/>
              <InterestPageLayout/>
            </Container.Sub>
              
          </Container.Right>
        </Container.Main>
      </MainSection>
    </>
  )
}

export default InterestPage