import React from 'react'
import Container from '../../Layout/Container/Container'
import LeftPanelContent from '../../Features/LeftPanelContent/LeftPanelContent'
import SideBarToggle from '../../Features/SideBar/SideBarToggle'
import MainSection from '../../Layout/MainSection/MainSection'
import WelcomePageLayout from '../../Layout/WelcomePageLayout/WelcomePageLayout'

const WelcomePage = () => {
  return (
    <>
      <MainSection>
        <Container.Main className={'sidebar-open'}>
          <Container.Left>
            <LeftPanelContent />
          </Container.Left>
          <Container.Right>
            <Container.Sub>
              <SideBarToggle/>
              <WelcomePageLayout/>
            </Container.Sub>
              
          </Container.Right>
        </Container.Main>
      </MainSection>
    </>
  )
}

export default WelcomePage