import React from 'react'
import ConversationContentLayout from '../../Layout/ConversationContent/ConversationContent';
import ConversationContentComponent from '../../Component/ConversationContent/ConversationContent';
import ConversationContentRightComponent from '../../Component/ConversationContent/ConversationReceiveContent';

const ConversationContent = ({messages}) => {
    return (

        <ConversationContentLayout>
            {
            
            messages.map((message, input) => (
                (message?.response)?
                <>
                    <ConversationContentRightComponent message={message.message} input={input} imgs={message?.imgs} time={message.time} isLoading={message?.isLoading}/>
                </>:
                <>
                    <ConversationContentComponent message={message.message} input={input} imgs={message?.imgs} time={message.time}/>
                </>
                
            ))}
        </ConversationContentLayout>
            
    )
}

export default ConversationContent;