
import { parsePhoneNumber } from 'libphonenumber-js';

const interestValidtor = {
    getCountryCode() {
        return [
            { code: '+1', country: 'USA/Canada' },
            { code: '+7', country: 'Russia/Kazakhstan' },
            { code: '+20', country: 'Egypt' },
            { code: '+27', country: 'South Africa' },
            { code: '+30', country: 'Greece' },
            { code: '+31', country: 'Netherlands' },
            { code: '+32', country: 'Belgium' },
            { code: '+33', country: 'France' },
            { code: '+34', country: 'Spain' },
            { code: '+36', country: 'Hungary' },
            { code: '+39', country: 'Italy' },
            { code: '+40', country: 'Romania' },
            { code: '+41', country: 'Switzerland' },
            { code: '+43', country: 'Austria' },
            { code: '+44', country: 'UK' },
            { code: '+45', country: 'Denmark' },
            { code: '+46', country: 'Sweden' },
            { code: '+47', country: 'Norway' },
            { code: '+48', country: 'Poland' },
            { code: '+49', country: 'Germany' },
            { code: '+51', country: 'Peru' },
            { code: '+52', country: 'Mexico' },
            { code: '+54', country: 'Argentina' },
            { code: '+55', country: 'Brazil' },
            { code: '+56', country: 'Chile' },
            { code: '+57', country: 'Colombia' },
            { code: '+58', country: 'Venezuela' },
            { code: '+60', country: 'Malaysia' },
            { code: '+61', country: 'Australia' },
            { code: '+62', country: 'Indonesia' },
            { code: '+63', country: 'Philippines' },
            { code: '+64', country: 'New Zealand' },
            { code: '+65', country: 'Singapore' },
            { code: '+66', country: 'Thailand' },
            { code: '+81', country: 'Japan' },
            { code: '+82', country: 'South Korea' },
            { code: '+84', country: 'Vietnam' },
            { code: '+86', country: 'China' },
            { code: '+90', country: 'Turkey' },
            { code: '+91', country: 'India' },
            { code: '+92', country: 'Pakistan' },
            { code: '+93', country: 'Afghanistan' },
            { code: '+94', country: 'Sri Lanka' },
            { code: '+95', country: 'Myanmar' },
            { code: '+98', country: 'Iran' },
            { code: '+212', country: 'Morocco' },
            { code: '+213', country: 'Algeria' },
            { code: '+216', country: 'Tunisia' },
            { code: '+218', country: 'Libya' },
            { code: '+220', country: 'Gambia' },
            { code: '+221', country: 'Senegal' },
            { code: '+222', country: 'Mauritania' },
            { code: '+223', country: 'Mali' },
            { code: '+234', country: 'Nigeria' },
            { code: '+250', country: 'Rwanda' },
            { code: '+251', country: 'Ethiopia' },
            { code: '+254', country: 'Kenya' },
            { code: '+255', country: 'Tanzania' },
            { code: '+256', country: 'Uganda' },
            { code: '+260', country: 'Zambia' },
            { code: '+263', country: 'Zimbabwe' },
            { code: '+351', country: 'Portugal' },
            { code: '+352', country: 'Luxembourg' },
            { code: '+353', country: 'Ireland' },
            { code: '+354', country: 'Iceland' },
            { code: '+355', country: 'Albania' },
            { code: '+358', country: 'Finland' },
            { code: '+359', country: 'Bulgaria' },
            { code: '+370', country: 'Lithuania' },
            { code: '+371', country: 'Latvia' },
            { code: '+372', country: 'Estonia' },
            { code: '+380', country: 'Ukraine' },
            { code: '+381', country: 'Serbia' },
            { code: '+385', country: 'Croatia' },
            { code: '+386', country: 'Slovenia' },
            { code: '+420', country: 'Czech Republic' },
            { code: '+421', country: 'Slovakia' },
            { code: '+500', country: 'Falkland Islands' },
            { code: '+501', country: 'Belize' },
            { code: '+502', country: 'Guatemala' },
            { code: '+503', country: 'El Salvador' },
            { code: '+504', country: 'Honduras' },
            { code: '+505', country: 'Nicaragua' },
            { code: '+506', country: 'Costa Rica' },
            { code: '+507', country: 'Panama' },
            { code: '+591', country: 'Bolivia' },
            { code: '+592', country: 'Guyana' },
            { code: '+593', country: 'Ecuador' },
            { code: '+595', country: 'Paraguay' },
            { code: '+598', country: 'Uruguay' },
            { code: '+673', country: 'Brunei' },
            { code: '+674', country: 'Nauru' },
            { code: '+675', country: 'Papua New Guinea' },
            { code: '+676', country: 'Tonga' },
            { code: '+677', country: 'Solomon Islands' },
            { code: '+678', country: 'Vanuatu' },
            { code: '+679', country: 'Fiji' },
            { code: '+680', country: 'Palau' },
            { code: '+685', country: 'Samoa' },
            { code: '+686', country: 'Kiribati' },
            { code: '+687', country: 'New Caledonia' },
            { code: '+689', country: 'French Polynesia' },
            { code: '+850', country: 'North Korea' },
            { code: '+852', country: 'Hong Kong' },
            { code: '+853', country: 'Macau' },
            { code: '+855', country: 'Cambodia' },
            { code: '+856', country: 'Laos' },
            { code: '+880', country: 'Bangladesh' },
            { code: '+886', country: 'Taiwan' },
            { code: '+960', country: 'Maldives' },
            { code: '+961', country: 'Lebanon' },
            { code: '+962', country: 'Jordan' },
            { code: '+963', country: 'Syria' },
            { code: '+964', country: 'Iraq' },
            { code: '+965', country: 'Kuwait' },
            { code: '+966', country: 'Saudi Arabia' },
            { code: '+967', country: 'Yemen' },
            { code: '+968', country: 'Oman' },
            { code: '+970', country: 'Palestinian Territory' },
            { code: '+971', country: 'United Arab Emirates' },
            { code: '+972', country: 'Israel' },
            { code: '+973', country: 'Bahrain' },
            { code: '+974', country: 'Qatar' },
            { code: '+975', country: 'Bhutan' },
            { code: '+976', country: 'Mongolia' },
            { code: '+977', country: 'Nepal' },
            { code: '+992', country: 'Tajikistan' },
            { code: '+993', country: 'Turkmenistan' },
            { code: '+994', country: 'Azerbaijan' },
            { code: '+995', country: 'Georgia' },
            { code: '+996', country: 'Kyrgyzstan' },
            { code: '+998', country: 'Uzbekistan' }
        ];
    },

    checkPhoneNumberValid(str) {
        try {
            const parsedVal = parsePhoneNumber(str);
            return parsedVal.isValid();
        } catch (e) {
            console.log('Error', e);
            return false;
        }
    }
}

export default interestValidtor;