import React from 'react'
import './ConversationContent.css';
import Logo1 from '../../Assets/Logo1.svg';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TypingBall from '../../Assets/TypingBall.svg';

const ConversationReceiveContent = ({ message, time, input, imgs, isLoading }) => {
    return (
        <>
            <div className='chat-receive-container-right'>
                <div className='chat-receive-container-right-image'>
                    <img src = {Logo1} alt='Jishi Logo'/>
                </div>
                <div key={input} className="chat-receive-container-right-inner">
                    <span className='time-span'>{time}</span>
                    <br />
                    {imgs ? (<img src={imgs} alt='Pagec' />):''}
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {message+''+(isLoading? '&nbsp;![Typing Ball Image]('+TypingBall+')':'')}
                    </ReactMarkdown>
                    
                </div>
            </div>
        </>
    );
};

export default ConversationReceiveContent;