import React from "react";
import './SideBarToggle.css'
const SideBarToggle = ({isSidebarOpen,toggleSidebar}) => (

    <div className={`sidebar-toggle-button`} onClick={toggleSidebar}>
      {isSidebarOpen ? '❮' : '❯'}
    </div>

);

export default SideBarToggle;