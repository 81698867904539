import React from 'react'
import './Container.css'
const Container = ({children, variant='main'}) => {
  return (
    <div className={'container-'+variant}>
        {children}
    </div>
  )
}

const Main = ({...props}) => <Container {...props} variant='main'/>
const Left = ({...props}) => <Container {...props} variant='left'/>
const Right = ({...props}) => <Container {...props} variant='right'/>
const Sub = ({...props}) => <Container {...props} variant='sub'/>

Container.Main = Main;
Container.Left = Left;
Container.Right = Right;
Container.Sub = Sub;

export default Container;
