import React, { useRef, useState } from 'react';
import CharBarComponent from '../../Component/ChatBar/ChatBar';
import useChat from '../../Contexts/ChatContext';
import useUpload from '../../Contexts/UploadContext';
import File from '../../Assets/Page.svg';
import randomMDString from '../../Utils/randomDemoMDString';
import genericUtils from '../../Utils/genericUtils';

const ChatFooter = () => {
    const fileInputRef = useRef(null);
    const [message, setMessage] = useState('');
    const { sendMessage, responseMessage } = useChat();
    const { uploadFile } = useUpload();
    

    const onClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('File selected:', file.name);
            uploadFile(file.name);
            sendMessage({message:' '+file.name +' (100%)', imgs:File});
            // You can process the file here
        }
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = async (e) => {
        if (message.trim()) {
            sendMessage({message});
            setMessage('');
            
            const response = responseMessage();
            await response.createMsg()

            for(const msg of randomMDString){
                await response.setToken(msg);
                await genericUtils.sleep(10);
            }
            await response.setComplete()
            
            
        }
    };


    return (
        <CharBarComponent fileInputRef={fileInputRef} 
            handleFileChange={handleFileChange} 
            onClick={onClick}
            message={message}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            handleSendMessage={handleSendMessage}></CharBarComponent>
    )
}

export default ChatFooter;