import React from "react";
import SideBarToggleComponent from "../../Component/SideBarToggle/SideBarToggle";
import useSidebarToggle from "../../Contexts/SideBarToggleContext";

const SideBarToggle = () => {
    const { sideBarToggleStatus, flipSideBar } = useSidebarToggle()
    return (
        <SideBarToggleComponent isSidebarOpen={sideBarToggleStatus} toggleSidebar={flipSideBar}/>
    );
}


export default SideBarToggle;