import React from 'react';
import Logo1 from '../../Assets/Logo1.svg';
import '../WelcomeHeader/WelcomeHeader.css'


const WelcomeHeader = ({children}) => {
    return (
        <div className="Wc-Page-header">
            <div className='Logo-Header-Welcome'>
            <img src={Logo1} alt='Jishi Pro Logo'/>
            </div>
            
            <div className="welcome-message">
                <p>{children}</p>
            </div>
        </div>
    );
};

export default WelcomeHeader;
