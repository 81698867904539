import React from 'react'
import './JishiPro.css'
import JishiProLogo from '../../Assets/JishiProLogo.svg';
import JishiProText from '../../Assets/JishiProText.svg';
import JishiProTick from '../../Assets/JishiProTick.svg';





const JishiPro = () => {
    return (
        <div className='main-card'>
            <div className='jish-pro-card'>
                <span className='jishi-inner-text'>
                    <img src={JishiProText} alt='Jishi Pro Text'/>
                    <img src={JishiProLogo} alt='Jishi Pro Logo'/>

                </span>
            </div>

            <div className='jishi-pro-content'>
                <h2>Upgrade to Pro</h2>
                <span>
                    <div className="jishi-pro-content-container">
                        <div className="jishi-pro-content-row">
                            <div className="jishi-pro-content-column jishi-pro-content-left"><img src={JishiProTick} alt='tick mark' /></div>
                            <div className="jishi-pro-content-column jishi-pro-content-left">Unlimited Requests</div>
                        </div>
                        <div className="jishi-pro-content-row">
                            <div className="jishi-pro-content-column jishi-pro-content-left"><img src={JishiProTick} alt='tick mark' /></div>
                            <div className="jishi-pro-content-column jishi-pro-content-left">Access to Pro Features</div>

                        </div>
                        <div className="jishi-pro-content-row">
                            <div className="jishi-pro-content-column jishi-pro-content-left"><img src={JishiProTick} alt='tick mark' /></div>
                            <div className="jishi-pro-content-column jishi-pro-content-left">Unlimited File Uploads</div>
                        </div>
                    </div>
                </span>
                <div className='jishi-pro-footer'>
                    <a href=''>Learn More &gt;</a>
                </div>
            </div>

        </div>
    )
}

export default JishiPro