import React from 'react'
import DropDown from '../DropDownMenu/DropDown'
import SearchBar from '../SearchBar/SearchBar';
import './ChatHeader.css'
const ChatHeader = () => {
    return (
        <div className='chat-header-flex'>
            <div className='chat-header-row'>
                <DropDown.Primary>
                    <option value={'MediAssist'}>MediAssist</option>
                </DropDown.Primary>
            </div>
            <div className='chat-header-row chat-align-right'>
                <SearchBar />
            </div>
        </div>
    )
}

export default ChatHeader;
