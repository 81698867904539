import React from 'react';
import './WelcomePageLayout.css'; // Dedicated CSS file for the layout
import WelcomeCardHeader from '../../Features/WelcomeCard/WelcomeCardHeader';
import CardContainer from '../../Component/CardContainerWelcome/CardContainer';
import ChooseJishi from '../../Component/ChooseJishi/ChooseJishi';

const WelcomePageLayout = () => {
  return (
    <div className="welcome-container">
      <WelcomeCardHeader/>
      <ChooseJishi>CHOOSE JISHI</ChooseJishi>
      <CardContainer />
    
    </div>
  );
}

export default WelcomePageLayout;
