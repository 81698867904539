import React, { useState } from "react";
import InterestPageMainLayout from "../../Layout/InterestPageMainLayout/InterestPageMainLayout";
import interestValidtor from "../../Utils/interestValidator";
const InterestPageContent = ()=>{
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        industry: '',
        phoneCode:'+91'
      });
    
      const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      });
    
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhoneNumber = (phoneCode,phoneNumber) => {
        return interestValidtor.checkPhoneNumberValid(phoneCode+''+phoneNumber);
      };
    
      const handleValidation = () => {
        let formIsValid = true;
        let errorsCopy = { ...errors };
    
        if (formData.firstName.length < 1) {
          errorsCopy.firstName = 'First name is required';
          formIsValid = false;
        } else {
          errorsCopy.firstName = '';
        }
    
        if (formData.lastName.length < 1) {
          errorsCopy.lastName = 'Last name is required';
          formIsValid = false;
        } else {
          errorsCopy.lastName = '';
        }
    
        if (!validateEmail(formData.email)) {
          errorsCopy.email = 'Invalid email address';
          formIsValid = false;
        } else {
          errorsCopy.email = '';
        }
    
        if (!validatePhoneNumber(formData.phoneCode, formData.phoneNumber)) {
          errorsCopy.phoneNumber = 'Invalid phone number';
          formIsValid = false;
        } else {
          errorsCopy.phoneNumber = '';
        }
    
        setErrors(errorsCopy);
        return formIsValid;
      };
    
      const handleChange = (field, value) => {
        if(errors[field]) setErrors({...errors, [field]: ''})
        setFormData((prev) => ({ ...prev, [field]: value }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
          console.log('Form Submitted:', formData);
          
        }
      };
    return (<>
    <InterestPageMainLayout
    firstName={formData.firstName}
    lastName={formData.lastName}
    email={formData.email}
    phoneNumber={formData.phoneNumber}
    phoneCode={formData.phoneCode}
    companyName={formData.companyName}
    industry={formData.industry}
    onChange={handleChange}
    errors={errors}
    onSubmit={handleSubmit}
    />
    </>)
}

export default InterestPageContent;