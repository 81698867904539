import React from 'react';
import './WelcomePageLayout.css'; // Dedicated CSS file for the layout
import ChooseJishi from '../../Component/ChooseJishi/ChooseJishi';
import InterestPageHeader from '../../Features/WelcomeCard/InterestPageCardHeader';
import InterestPageContent from '../../Features/InterestPageContent/InterestPageContent'

const InterestPageLayout = () => {
  return (
    <div className="welcome-container">
      <InterestPageHeader/>
      <ChooseJishi>REGISTER INTEREST</ChooseJishi>
      <InterestPageContent />
    
    </div>
  );
}

export default InterestPageLayout;
