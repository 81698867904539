import React from 'react';
import Logo from "../Logo/Logo";
import Button from "../Button/Button";
import Explore from '../../Assets/Explore.svg';
import SignUp from '../../Assets/SignUp.svg';

const InterestPageLeftPanelContentSmall = ({onClick}) => {
    return (
        <>
            <Logo.Small className='margin-bottom-20'></Logo.Small>
            <div className = 'container-left-content'>

                <Button.Explore className='margin-bottom-20'>
                    <img src={Explore} alt='Explore'/>
                </Button.Explore>

            </div>
            <div className = 'container-left-mid'></div>
            <div className = 'container-left-bottom'>
                <Button.Search onclick={onClick} disabled = {true}><img src = {SignUp} alt='SignUp'/></Button.Search>
            </div>
            
        </>
    );
};

export default InterestPageLeftPanelContentSmall;