import React from 'react';
import MainSection from '../../Layout/MainSection/MainSection';
import Container from '../../Layout/Container/Container';
import ChatHeader from '../../Component/ChatHeader/ChatHeader';
import './ChatPage.css';
import ChatLayout from '../../Layout/ChatLayout/ChatLayout';
import ChatBar from '../../Features/ChatBar/ChatBar';
import ConversationContentMain from '../../Features/ConverstaionContent/ConversationContentMain';
import ChatBarHeader from '../../Features/ChatBarHeader/ChatBarHeader';
import LeftPanelContent from '../../Features/LeftPanelContent/LeftPanelContent';
import SideBarToggle from '../../Features/SideBar/SideBarToggle';
import RightBarFileUpload from '../../Features/RightBarFileUpload/RightBarFileUpload';

const ChatPage = () => {
  return (
    <MainSection>
      <Container.Main className={'sidebar-open'}>
          <Container.Left>
            <LeftPanelContent />
          </Container.Left>
        <Container.Right> 
          <Container.Sub>
            <ChatLayout.MainContent>
            <SideBarToggle/>
              <ChatLayout.ChatContentLeft>
                <ChatLayout.ChatContent>
                  <ChatHeader />
                  <ConversationContentMain/>
                  <ChatLayout.FooterContent>
                    <ChatBarHeader />
                    <ChatBar />
                  </ChatLayout.FooterContent>
                </ChatLayout.ChatContent>
              </ChatLayout.ChatContentLeft>
              <ChatLayout.UploadContent>
              <RightBarFileUpload/>
              </ChatLayout.UploadContent>
            </ChatLayout.MainContent>
          </Container.Sub>
        </Container.Right>
      </Container.Main>
    </MainSection>
  );
};

export default ChatPage;  