import React from 'react';
import Logo from "../Logo/Logo";
import Button from "../Button/Button";
import JishiProMini from "../JishiProCard/JishiProMini";
import Explore from '../../Assets/Explore.svg';
const LeftPanelContent = ({onClick}) => {
    return (
        <>
            <Logo.Small className='margin-bottom-20'></Logo.Small>
            <div className = 'container-left-content'>

                <Button.Explore className='margin-bottom-20'>
                    <img src={Explore} alt='Explore'/>
                </Button.Explore>
                <JishiProMini className='margin-bottom-20' />
            </div>
            <div className = 'container-left-mid'></div>
            <div className = 'container-left-bottom'>
                <Button.Search onclick={onClick}>K</Button.Search>
            </div>
            
        </>
    );
};

export default LeftPanelContent;