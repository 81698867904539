import React from "react";
import useSidebarToggle from "../../Contexts/SideBarToggleContext";
import InterestPageLeftPanelContent from "../../Component/LeftPanelConent/InterestPageLeftPanelContent";
import InterestPageLeftPanelContentSmall from "../../Component/LeftPanelConent/InterestPageLeftPanelContentSmall";
const InterestPageLeftPanelConent = () => {
    const { sideBarToggleStatus } = useSidebarToggle();
    return (
        <>
            {
                (sideBarToggleStatus) ? <><InterestPageLeftPanelContent /></> : <><InterestPageLeftPanelContentSmall/></>
            }
        </>
    )
}

export default InterestPageLeftPanelConent;