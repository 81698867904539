const getFormattedTime = () => {
        const now = new Date();
    
        // Get the current hours and minutes
        let hours = now.getHours();
        let minutes = now.getMinutes();
    
        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
    
        // Format minutes to be always two digits
        minutes = minutes < 10 ? '0' + minutes : minutes;
    
        // Format the time as "11:12 AM"
        const timeString = `${hours}:${minutes} ${ampm}`;
    
        // Return the full formatted string
        return `Today ${timeString}`;
}
export default getFormattedTime;