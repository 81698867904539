import React from 'react';
import './InputText.css';

const InputText = ({ placeholder, value, onChange, variant }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className={"input-text-"+variant}
      value = {value}
      onChange={onChange}
    />
  );
};

const Primary = ({...props}) => <InputText {...props} variant =  {"primary"} />;
const Secondary = ({...props}) => <InputText {...props} variant = {"secondary"} />;

InputText.Primary =  Primary;
InputText.Secondary = Secondary;

export default InputText;