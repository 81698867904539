import React from "react";
import './RightPanelNoUpload.css'
import NoFile from '../../Assets/NoFile.svg'
import Button from "../Button/Button";

const RightPanelNoUpload = ({onClick})=>{
    return (
    <div className = 'right-panel-no-upload-main-content'>
        <div className = 'right-panel-no-upload-inner-content'>
            <img src={NoFile} alt="No File"/>
            <p><h1>No Files Available</h1></p>
            <p>Start By Uploading your First File</p>
            <p><Button.Primary>Upload File</Button.Primary></p>
        </div>
    </div>);
}

export default RightPanelNoUpload;