import React from 'react';
import './Button.css';

const Button = ({ children, onClick, type = 'button', disabled = false, className = '', variant }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`button-${variant} ${className}`}
    >
      {children}
    </button>
  );
};

const Primary = (props) => <Button {...props} variant="primary" />;
const Secondary = (props) => <Button {...props} variant="secondary" />;
const Explore = (props) => <Button {...props} variant="explore" />;
const Search = (props) => <Button {...props} variant="search" />;
const Upload = (props) => <Button {...props} variant="upload" />;
const ChatPrimary = (props) => <Button {...props} variant="chat-primary" />;
const Help = (props) => <Button {...props} variant="help" />;




Button.Primary = Primary;
Button.Secondary = Secondary;
Button.Explore = Explore;
Button.Search = Search;
Button.Upload = Upload;
Button.ChatPrimary = ChatPrimary;
Button.Help = Help;




export default Button;
