import React from 'react'
import './JishiPro.css'
import JishiProLogo from '../../Assets/JishiProLogo.svg';

const JishiProMini = () => {
    return (
        <div>
            <div className='jish-pro-card'>
                <span className='jishi-inner-text'>
                    <img src={JishiProLogo} alt='JishiProLogo'/>
                </span>
            </div>
        </div>
        )
    }

export default JishiProMini;