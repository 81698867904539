import React from 'react';
import Button from '../Button/Button';
import './Login.css';
import Logo from '../../Assets/Logo1.svg';
import InputField from '../InputFeild/InputFeild';
import GoogleButton from '../Button/GoogleButton';
import AppleButton from '../Button/AppleButton/AppleButton';

const Login = () => {
  return (
    <>
        <div className="logo-placeholder">
        <img src={Logo}/>
        </div>
        <h2>Login/Create account to proceed</h2>
        <div>
        <GoogleButton/>
        <AppleButton/>
        </div>
      
        <div className="separator">
          <span>OR</span>
        </div>
        <InputField type="email" placeholder="Enter Your Email Id" />
        <div className='sec-btn'>
        <Button.Primary className="ButtonLogin">Proceed</Button.Primary>
        </div>
      
    </>
  );
};

export default Login;
