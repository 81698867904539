import React from 'react';
import Logo from "../Logo/Logo";
import Button from "../Button/Button";
import Explore from '../../Assets/Explore.svg';
const InterestPageLeftPanelContent = ({onClick}) => {
    return (
        <>
            <Logo.Full className='margin-bottom-20'></Logo.Full>
            <div className = 'container-left-content'>

                <Button.Explore className='margin-bottom-20'>
                    <img src={Explore} alt='Explore'/>
                    Explore Jishi
                </Button.Explore>
            </div>
            <div className = 'container-left-mid'></div>
            <div className = 'container-left-bottom'>
            
                <Button.Primary disabled = {true} Click={onClick} className='b-width-100'>Login/Signup</Button.Primary>
            </div>

        </>
    );
};

export default InterestPageLeftPanelContent;