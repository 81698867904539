import React,{ useEffect, useState } from "react";
import WelcomeHeaderComponent from "../../Component/WelcomeHeader/WelcomeHeader";
import genericUtils from "../../Utils/genericUtils";

const GenericFlashCardHeader = ({children})=>{
    const [message, setMessage] = useState('');
    
    useEffect(() => {
        const runOneByOne = async () => {
            for(const c of children){
                setMessage((prevMessage) => prevMessage + c);
                await genericUtils.sleep(20);
            }
        }
        runOneByOne();
    }, []);

    return (<><WelcomeHeaderComponent>{message}</WelcomeHeaderComponent></>)
}

export default GenericFlashCardHeader;