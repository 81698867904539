import React from "react";
import './InputPhoneNumber.css';
import interestValidtor from '../../Utils/interestValidator';
const InputPhoneNumber = ({selectedOption, value, handleChange, onChange})=>{
    return (
        <div className="phone-input">
                <select className="country-code-select" value={selectedOption} onChange={handleChange}>
                    {
                        interestValidtor.getCountryCode().map(ele=>(<option value={ele.code}>{ele.code}</option>))
                    }
                </select>
                <input type="tel" 
                    className="input-field" 
                    style={{ width:'100%'}} 
                    placeholder="Your Phone Number" 
                    value = {value}
                    onChange={onChange}
                    required/>
            </div>
    )
}

export default InputPhoneNumber;